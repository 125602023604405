<template>
    <SettingsForm />
</template>

    <script>
  import SettingsForm from '@/components/forms/SettingsForm.vue';
  
  export default {
    components: {
        SettingsForm
    }
  };
  
  </script>
