<template>
    <ListPfpGrid/>
</template>
  
  <script>
  import ListPfpGrid from '@/components/widgets/galleries/ListPfpGrid.vue';
  
  export default {
    components: {
        ListPfpGrid
    }
  };
  
  </script>