<template>
    <UploadForm/>
  </template>
  
  <script>
  import UploadForm from '@/components/forms/UploadForm.vue';
  
  export default {
    components: {
        UploadForm
    }
  };
  
  </script>