<template>
    <ListWallpaperGrid/>
</template>
  
  <script>
  import ListWallpaperGrid from '@/components/widgets/galleries/ListWallpaperGrid.vue';
  
  export default {
    components: {
      ListWallpaperGrid
    }
  };
  
  </script>