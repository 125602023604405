<template>
  <LoginForm/>
</template>

<script>
import LoginForm from '@/components/forms/LoginForm.vue';

export default {
  components: {
    LoginForm
  }
};

</script>