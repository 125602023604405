<template>
    <AccountDetails/>
    <MyScapeGrid/>
</template>
  
  <script>
  import AccountDetails from '@/components/widgets/myscape/AccountDetails.vue';
  import MyScapeGrid from '@/components/widgets/myscape/MyScapeGrid.vue';
  
  export default {
    components: {
      AccountDetails,
      MyScapeGrid
    }
  };
  
  </script>